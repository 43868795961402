import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import moment from 'moment-timezone';
import { Box, Divider, Grid, MenuItem, Typography } from '@material-ui/core';
import { appointmentCaptured, clearAppointment as appointmentCleared } from '../Actions/CustomerDetailsActions';
import UPDatePicker from '../Shared/UPDatePicker';
import { fetchPractitioners, fetchPractitionersAvailability } from '../HttpHelpers/PractitionerServiceHttpHelper';

import StyledButton from '../components/StyledButton';
import UPLoader from '../Shared/UPLoader';
import PractitionerTimeSlots from './PractitionerTimeSlots';
import UPSelectFormControl from '../components/UPFormComponents/UPSelectFormControl';
import { AUTH_ACCESS_METHOD, QueryParameterNames, SessionStorageVariables } from '../Shared/Constants';
import ImageMask from '../Shared/ImageMask';
import UPSelect from '../components/UPFormComponents/UPSelect';
import { sendLinkClick } from '../Analytics/AnalyticsHelper';
import { getApplicablePageInfo } from '../Shared/Services/PageService';

class PractitionerAvailability extends Component {
    showPractitioners = true;

    constructor(props) {
        super(props);
        this.state = {
            programName: null,
            timeZone: [],
            selectedTimeZone: moment.tz.guess().search('Australia') > -1 ? moment.tz.guess() : 'Australia/Melbourne',
            selectedDate: moment().format('DD-MMM-YYYY').toString(),
            morningAppointment: [],
            afternoonAppointment: [],
            appointmentDates: [],
            appointmentStartTimes: [],
            practitioners: [],
            availablePractitioners: [],
            selectedStartTime: null,
            loading: true,
            appointmentTime: [],
            enableNext: true,
            selectedDateFormat: 'DD-MMM-YYYY',
            format: 'YYYY-MM-DD HH:mm:ss',
            selectedMonth: moment.utc().format('YYYY-MM-DD').toString(),
            tz: 'Australia/Melbourne',
            timeZoneFormat: 'YYYY-MM-DD',
            from: '',
            to: '',
            subscription: props.customerDetails.subscription,
            activeSubscription: props.auth?.user?.activeSubscription,
            appointmentCode: null,
            flow: '',
            availablePractitionerFilter: [],
            selectedPractitionerFilter: '0'
        };

    }

    componentDidMount() {
        const searchQuery = this.props.router.location.search;
        const searchParams = new URLSearchParams(searchQuery);
        const programName = searchParams.get(QueryParameterNames.P) ?? '';
        const flow = searchParams.get(QueryParameterNames.F) ?? '';
        this.setState({ programName, flow });

        const from = moment().format(this.state.format);
        const to = moment().clone().endOf('month').format(this.state.format);
        this.setState({
            timeZone: moment.tz.names().filter(name => name.includes('Australia')),
            from,
            to,
        });

        this.getPractitioners();
        this.getPractitionersAvailability(from, to, this.state.selectedTimeZone, programName, this.state.selectedPractitionerFilter);

    }

    goNextStep() {
        this.props.dispatch(push(`${this.props.nextPage}?p=${this.state.programName}&f=${this.state.flow}`));
    }

    goPrevStep() {
        const groupPageInfo = getApplicablePageInfo(this.state.flow, this.state.subscription.group, this.props.router.location.pathname);
        this.props.dispatch(push(`${groupPageInfo.currentPage.previousPage}?p=${this.state.programName}&f=${this.state.flow}`));
    }

    bookLater() {
        sendLinkClick('Book Later Button', 'Book Later',`${this.props.nextPage}`, window.location.pathname, null);
        this.props.dispatch(appointmentCleared());
        this.goNextStep();
    }

    bookNow() {
        if (this.state.availablePractitioners?.length > 0) {
            let practitioner = this.state.availablePractitioners[0];
            if (this.state.availablePractitioners.length > 1) {
                practitioner = this.state.availablePractitioners[Math.floor(Math.random() * this.state.availablePractitioners.length)];
            }
            this.selectPractitioner(practitioner.id, practitioner.firstName + ' ' + practitioner.lastName, practitioner.metadata?.profilePhotoUrl);
            sendLinkClick('Book Now Button', 'Book Now',`${this.props.nextPage}`, window.location.pathname, null);
        }
    }

    handleChange = (event) => {
        this.setState({ selectedTimeZone: event.target.value });
        let from = moment.utc(this.state.selectedMonth).format(this.state.format);
        if(moment(this.state.selectedMonth).month() === moment().month()) {
            from = moment().format(this.state.format);
        }

        const to = moment(this.state.selectedMonth).clone().endOf('month').format(this.state.format);
        this.setState({ from });
        this.setState({ to });
        this.getPractitionersAvailability(from, to, event.target.value, this.state.programName, this.state.selectedPractitionerFilter);
    };

    getAppointments = (date) => {
        this.setState({ appointmentTime: [] });
        const data = this.state.appointmentStartTimes.filter(({ appointmentStartLocal }) => appointmentStartLocal.indexOf(date.format(this.state.timeZoneFormat)) === 0);
        if(!data.length) {
            return;
        }

        const appointmentTime = data.map(({ appointmentStartLocal }) => {
            const time = appointmentStartLocal.split('T')[1];
            const formatted = moment(time, 'HH:mm:ss').format('LT');
            const twentyFourHr = moment(time, 'HH:mm:ss').hours();
            return { formatted, twentyFourHr };
        });
        this.setState({ appointmentTime });
    }

    getPractitionersAvailability = (from, to, timeZone, programName, practitionerId) => {
        this.setState({ loading: true });

        let program = this.state.subscription?.group;
        let stored = sessionStorage.getItem(SessionStorageVariables.AccessStatus);
        if(stored) {
            stored = JSON.parse(stored);
            program = stored.access === AUTH_ACCESS_METHOD.FOLLOWUP_BOOKING ? `${stored.program}_followup` : stored.program;
        }
        const params = {
            timeZone: timeZone,
            to: moment(to).clone().tz(this.state.tz).format(this.state.timeZoneFormat),
            from: moment(from).clone().tz(this.state.tz).format(this.state.timeZoneFormat),
            appointmentCode: !program ? programName : program,
            id: practitionerId
        };

        this.setState({ appointmentCode: params.appointmentCode});
        this.clear();

        fetchPractitionersAvailability(params).then((res) => {
       
            if(!res.appointmentDates) {
                this.setState({ loading: false });
                return;
            }

            const appointmentStartTimes = res.appointmentStartTimes.map(x => { return {...x, appointmentStart : moment.utc(x.appointmentStart)};});
            this.setState({ appointmentDates: res.appointmentDates });
            this.setState({ appointmentStartTimes: appointmentStartTimes });
            this.setState({ practitioners: res.practitioners });

            let selectedDate = moment(new Date(), this.state.selectedTimeZone);
            if(this.state.selectedDate?.length > 0) {
                selectedDate = moment(this.state.selectedDate);
            }
            this.getAppointments(selectedDate);
            this.setState({ loading: false });
        }).catch((error) => {
            this.setState({ loading: false });
            this.clear();
            console.log(error);
        });
    }


    getPractitioners = () => {
        fetchPractitioners().then((res) => {
            if(!res) {
                return;
            }
            res.unshift({ id: '0', firstName: 'All' , lastName: 'Physios' });
            this.setState({ availablePractitionerFilter: res });
        }).catch((error) => {

            console.log(error);
        });
    }

    clear() {
        this.setState({ appointmentDates: [] });
        this.setState({ appointmentStartTimes: [] });
        this.setState({ practitioners: [] });
        this.setState({ availablePractitioners: [] });
        this.setState({ selectedStartTime: null });

        this.setState({ selectedDate: null });
        this.setState({ appointmentTime: [] });
    }


    handlePractitionerFilterChanged = (practitionerId) => {
        this.setState({ selectedPractitionerFilter: practitionerId });
        this.getPractitionersAvailability(this.state.from, this.state.to, this.state.selectedTimeZone, this.state.programName, practitionerId);
    }

    handleChangedDate = (date) => {
        const localDate = moment(date, this.state.selectedTimeZone);
        this.setState({ selectedDate: localDate.format(this.state.selectedDateFormat) });
        this.getAppointments(localDate);
    }

    handleMonthChange = (date) => {
        const to = moment(date).clone().endOf('month').format(this.state.format);
        let from = moment.utc(to).clone().startOf('month').format(this.state.format);
        if(moment(date).month() === moment().month()) {
            from = moment().format(this.state.format);
        }

        this.setState({ selectedMonth: from });
        this.setState({ from });
        this.setState({ to });
        this.getPractitionersAvailability(from, to, this.state.selectedTimeZone, this.state.programName, this.state.selectedPractitionerFilter);
    }

    handleAppointmentTime = (time) => {
        if(!time) {
            this.setState({ enableNext: true });
            return;
        }

        this.setState({ enableNext: false });

        const timeData = moment(`${time}`, 'hh:mm A').format('HH:mm');
        const date = moment(this.state.selectedDate).format(this.state.timeZoneFormat);
        const startTime = moment.tz(`${date} ${timeData}`, this.state.selectedTimeZone);
        const selectedTimeslot = this.state.appointmentStartTimes.find(a => {
            const momentStartDate = a.appointmentStart.clone().tz(this.state.selectedTimeZone);
            return  momentStartDate.format(this.state.format) === startTime.format(this.state.format);
        });
        if (selectedTimeslot) {
            const availablePractitioners = this.state.practitioners.filter(p => selectedTimeslot.practitionerId.includes(p.id));
            this.setState({ selectedStartTime: startTime,  availablePractitioners: availablePractitioners });
        }
    }

    selectPractitioner(practitionerId, practitionerName, photoUrl)
    {
        const existingAppointment = this.props.customerDetails.appointment;
        const patient = JSON.parse(sessionStorage.getItem(SessionStorageVariables.Patient));
        const startTimeUtc = new moment(this.state.selectedStartTime).utc();

        const data = {
            ...existingAppointment,
            startTime: startTimeUtc,
            startTimeTz: this.state.selectedStartTime,
            practitionerId: practitionerId,
            patientId: !patient ? null : patient.patientId,
            practitionerName: practitionerName,
            practitionerPhotoUrl: photoUrl,
            subscriptionId: this.state.subscription ? this.state.subscription.id : this.state.activeSubscription?.subscriptionId,
            appointmentCode: this.state.appointmentCode,
            tz: this.state.selectedTimeZone
        };
        this.props.dispatch(appointmentCaptured(data));
        this.goNextStep();
    }


    render() {
        return (
            <Box sx={{ maxWidth: 960, margin: 'auto' }}>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography component="div" variant="subtitle2" paragraph={true} align="center" style={{
                        fontSize: ' 18px',
                        lineHeight: '150%',
                        textAlign: 'center',
                        letterSpacing: '0.03em',
                        color: '#ffffff',
                        width: '414px'
                    }}>
                        Select your preferred date and time from the available online appointments
                    </Typography>
                </Box>
                {this.state.loading &&(<UPLoader containerHeight='100vh'/>)}
                <form onSubmit={(event) => {
                    event.preventDefault();
                    this.goNextStep(event);

                }}>

                    <Box style={{ display: 'flex', justifyContent: 'center' }}>
                        <Grid container spacing={2}>

                            <Grid item xs={12} sm={12} md={6}>
                                <Box sx={{ maxWidth: '486px', minHeight: '360px', backgroundColor: '#282828', marginTop: '8px' }}>
                                    <UPDatePicker appointmentDates={this.state.appointmentDates} handleChangedDate={this.handleChangedDate} handleMonthChange={this.handleMonthChange} tz={this.state.selectedTimeZone} />
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} style={{ marginTop: 0, paddingTop: 0 }}>
                                <UPSelectFormControl style={{ display: 'flex' }}>
                                    <Typography variant="h6" style={{ fontSize: '16px', color: '#FFFFFF', padding: '0px 0px 6px 0px ', textAlign: 'left', marginTop: 0 }}>
                                        Select your time zone
                                    </Typography>
                                    <UPSelect
                                        labelId="time-zone-select-label"
                                        id="time-zone-select"
                                        onChange={this.handleChange}
                                        value={this.state.selectedTimeZone}
                                    >
                                        <MenuItem value="" disabled> Select Timezone </MenuItem>
                                        {this.state.timeZone.map((time, index) => (<MenuItem value={time} key={index}>{time}</MenuItem>))}
                                    </UPSelect>
                                </UPSelectFormControl>
                                <UPSelectFormControl style={{ display: 'flex' }}>
                                    <Typography variant="h6" style={{ fontSize: '16px', color: '#FFFFFF', padding: '0px 0px 6px 0px ', textAlign: 'left', marginTop: 0 }}>
                                        Showing availability for
                                    </Typography>
                                    <UPSelect
                                        labelId="time-zone-select-label"
                                        id="time-zone-select"
                                        onChange={(evt) => this.handlePractitionerFilterChanged(evt.target.value)}
                                        value={this.state.selectedPractitionerFilter}
                                    >
                                        {this.state.availablePractitionerFilter.map((practitioner, index) => (<MenuItem value={practitioner.id} key={index}>{practitioner.firstName + ' ' + practitioner.lastName}</MenuItem>))}
                                    </UPSelect>
                                </UPSelectFormControl>
                                <Box sx={{ p: 1, marginTop: 4}} >
                                    <PractitionerTimeSlots selectedDate={this.state.selectedDate} appointmentTime={this.state.appointmentTime} handleAppointmentTime={this.handleAppointmentTime} />
                                </Box>
                            </Grid>
                            {
                                (this.state.availablePractitioners?.length > 0 && this.showPractitioners) && this.state.availablePractitioners?.map((practitioner, index) =>
                                    <Grid item xs={12} md={12} key={index}>
                                        <Grid container>
                                            <Grid item xs={12} md={8}>
                                                <Box sx={{ color: '#000000', display: 'flex', paddingLeft: 16, justifyContent: 'left' }}>
                                                    <ImageMask imageUrl={practitioner.metadata?.profilePhotoUrl} />
                                                    <Box sx={{ alignItems: 'flex-start', display: 'flex', ml: '20px', flexDirection: 'column' }}>
                                                        <Box sx={{ display: 'flex', mt: '24px' }}>
                                                            <Typography style={{ color: '#FFFFFF', padding: '4px', fontSize: '16px', lineHeight: '100%', letterSpacing: '0.03em', textAlign: 'left' }}>{practitioner.designation}</Typography>
                                                        </Box>
                                                        <Box sx={{ display: 'flex' , flexDirection: 'column', alignItems: 'flex-start'}}>
                                                            <Typography style={{ color: '#FFFFFF', padding: '4px', fontSize: '24px', lineHeight: '100%', letterSpacing: '0.03em' }}>{practitioner.firstName + ' ' + practitioner.lastName}</Typography>
                                                        </Box>

                                                    </Box>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', height: '100%', paddingRight: 16 }} >
                                                    <StyledButton roundedInverse style={{ width: '80%', textTransform: 'capitalize' }} className="SubmitButton" type="submit" onClick={() => this.selectPractitioner(practitioner.id, practitioner.firstName + ' ' + practitioner.lastName, practitioner.metadata?.profilePhotoUrl)}>Select</StyledButton>
                                                </Box>

                                            </Grid>
                                        </Grid>

                                        <Divider style={{ backgroundColor: '#FFFFFF' , marginTop: 8}} orientation='horizontal' variant='middle' />
                                    </Grid>
                                )
                            }

                        </Grid>
                    </Box>



                    {(!this.state.loading) &&<Box sx={{ mt: 10 }} className="flex-center-vh" style={{gap: '20px'}}>
                        {
                            this.props.previousPage &&
                            <StyledButton roundedInverse style={{ }} type="button" onClick={(event) => this.goPrevStep(event)}>BACK</StyledButton>
                        }
                        {this.props.subscription && !this.props.subscription.appointmentRequired &&
                            <StyledButton roundedInverse type="button" onClick={() => this.bookLater()}>BOOK LATER</StyledButton>
                        }
                        {
                            (this.state.availablePractitioners?.length > 0 && !this.showPractitioners) &&
                            <StyledButton rounded type="button" onClick={() => this.bookNow()}>BOOK NOW</StyledButton>
                        }
                    </Box>}
                </form>
            </Box>
        );
    }
}

const mapStateToProps = (state) => {
    return state;
};

export default connect(mapStateToProps)(PractitionerAvailability);
